import React from "react";

import { MDXProvider } from "@mdx-js/react";
import { components } from "./src/components/componentsProvider";
import { InitModulesProvider } from "./src/hooks/useContext";
import breackpoints from "./src/js/breakpoints";
import browser from "./src/js/browser";

import { UserProvider } from "./src/context/userContext";

import { LessonProvider } from "./src/context/lessonsContext";
import { QuestionProvider } from "./src/context/questionsContext";
import { DetailsProvider } from "./src/context/detailsContext";

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    console.log(`# IntersectionObserver is polyfilled`);
    return import(`intersection-observer`);
  }
};

export const wrapRootElement = ({ element }) => (
  <InitModulesProvider breackpoints={breackpoints} browser={browser}>
    <MDXProvider components={components}>
      <LessonProvider>
        <UserProvider>
          <QuestionProvider>
            <DetailsProvider>{element}</DetailsProvider>
          </QuestionProvider>
        </UserProvider>
      </LessonProvider>
    </MDXProvider>
  </InitModulesProvider>
);

// export const onServiceWorkerUpdateReady = () => window.location.reload(true);
