import { v4 as uuid4 } from "uuid";

import {
  daysNamesAr,
  monthsNamesAr,
  levelsInfo,
  initStudentsInfo,
  initTrimester,
  subjects,
} from "../const";
import { setStudent, setPassword, setPoints } from "../../data";

export const getUrlFriendlyStr = (str, replacement = "-") => {
  return str.trim().replace(/\s/g, replacement);
};

export const arrayToObject = arr => {
  return arr.reduce((acc, value) => {
    return { ...acc, [value[0]]: value[1] };
  }, {});
};

export const getRandomInt = max => {
  return Math.ceil(Math.random() * Math.floor(max));
};

export const getRandomFloat = (max, precision = 2) => {
  const value = Math.random() * max;
  return value.toFixed(precision);
};

export const shuffle = (array = []) => {
  let newArray = array.slice(0);
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

export const getParams = (name, search) => {
  const params = decodeURI(search)
    .slice(1)
    .split("&")
    .map(v => {
      return v.split("=");
    })
    .reduce((acc, value) => {
      return { ...acc, [value[0]]: value[1] };
    }, {});
  return params[name];
};

export const randomArrayNum = (length, max) => {
  return Array.from({ length }).map(() => {
    const maxPerItem = max / length;
    return Math.ceil(Math.random() * maxPerItem);
  });
};

export const timeout = delay => {
  return new Promise(res => setTimeout(res, delay));
};

export const getCurrentSchoolYear = () => {
  const currentDate = new Date(),
    currentMonth = currentDate.getMonth() + 1,
    currentYear = currentDate.getFullYear();
  return currentMonth < 9 ? `${currentYear - 1}` : `${currentYear}`;
};

const levelId = {
    "السنة الأولى ثانوي": 1,
    أولى: 1,
    "السنة الثانية ثانوي": 2,
    ثانية: 2,
    "السنة الثالثة ثانوي": 3,
    ثالثة: 3,
  },
  divisionId = {
    "جذع مشترك آداب": 11,
    "جدع مشترك آداب": 11,
    "آداب وفلسفة": 21,
    "آداب و فلسفة": 21,
    "جذع مشترك علوم و تكنولوجيا": 12,
    "جدع مشترك علوم وتكنولوجيا": 12,
    "علوم تجريبية": 22,
    "علوم تجريبية": 32,
  };
export const subjectId = {
  "علوم فيزيائية": 3,
  رياضيات: 1,
  "علوم طبيعية": 2,
};

export const getClassroomId = ({ level, division, number, groupe }) => {
  return `3${levelId[level]}${divisionId[division]}00${number}_${groupe}`;
};

const createId = (values, identifier) => {
  const { level, division, order, position, subject, classroomId } = values;
  let str = ``;
  if (classroomId) {
    str += `${classroomId.slice(1, 4)}`;
  } else {
    if (level) {
      str += levelId[level];
    }
    if (division) {
      str += divisionId[division];
    }
  }
  if (subject) {
    str += subjectId[subject];
  }
  if (order) {
    str += `${order}${identifier === "l" ? "l" : ""}`;
  }
  if (position) {
    str += `${position}q`;
  }
  return str;
};

export const getQuestionId = values => {
  return createId(values, "q");
};
export const getLessonId = values => {
  return createId(values, "l");
};

export const roundNumber = (number, power = 2) => {
  return Math.round(number * (10 ^ power)) / (10 ^ power);
};

export const getCurrentDate = date => {
  const currentDate = date ? new Date(date) : new Date();
  return {
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
    time: `${currentDate.getHours()}:${currentDate.getMinutes()}`,
    day: currentDate.getDate(),
    dayName: currentDate.getDay(),
  };
};
export const getDate = date => {
  const { dayName, day, month, year } = getCurrentDate(date);
  return `${daysNamesAr[dayName]} ${day} ${monthsNamesAr[month]} ${year}`;
};
export const getAction = (
  cause,
  amount = -0.5,
  pointType = `ContinuousMonitoring.observationRevision`
) => {
  return {
    cause,
    mark: {
      pointType,
      amount,
    },
    observationDate: getDate(),
    type: "observation",
  };
};

export const getSubjects = phase => {
  return subjects[phase]
    ? ["إختر المادة", ...subjects[phase]]
    : ["إختر الطور قبل أن تختار المادة"];
};

export const getLevels = phase => {
  return levelsInfo[phase]
    ? [["إختر المستوى", 0], ...levelsInfo[phase].map(value => value.name)]
    : ["إختر الطور قبل أن تختار المستوى"];
};

export const getDivisions = level => {
  return levelsInfo[3][level - 1]
    ? [["إختر الشعبة", 0], ...levelsInfo[3][level - 1].divisions]
    : ["إختر المستوى قبل أن تختار الشعبة"];
};

export const createStudent = async ({
  teacherId,
  firstName,
  lastName,
  birthday,
  schoolYear,
  classroomId,
  sex,
  subject,
  IdN,
  continuousMonitoring,
}) => {
  const studentSK = encodeURI(
    getUrlFriendlyStr(`s#${firstName}-${lastName}-${birthday}`)
  ).replaceAll("%", "");
  const studentPassword = `pw#${uuid4()}`,
    parentPassword = `pw#${uuid4()}`;
  try {
    await setStudent({
      ...initStudentsInfo,
      id: uuid4(),
      PK: teacherId,
      SK: studentSK,
      IdN,
      GSI1_PK: teacherId,
      GSI1_SK: `c#${classroomId}#${schoolYear}1`,
      GSI2_PK: studentPassword,
      GSI2_SK: `${schoolYear}1`,
      GSI3_PK: parentPassword,
      GSI3_SK: `${schoolYear}1`,
      lastName,
      firstName,
      birthday,
      sex,
      subject,
      type: "student",
    });
  } catch (e) {
    console.error(e.message);
  }
  try {
    await setPoints({
      PK: teacherId,
      SK: `${studentSK}#${schoolYear}1`,
      GSI1_PK: teacherId,
      GSI1_SK: `c#${classroomId}#${schoolYear}1`,
      GSI2_PK: studentPassword,
      GSI2_SK: `${schoolYear}1`,
      GSI3_PK: parentPassword,
      GSI3_SK: `${schoolYear}1`,
      type: "point",
      continuousMonitoring,
      ...initTrimester,
    });
    await setPassword({
      PK: studentPassword,
      SK: studentPassword,
      teacherId,
      studentId: studentSK,
      type: "password",
    });
  } catch (e) {
    console.error(e.message);
  }
};

export const getLevel = level => {
  const byName = typeof level === "string";
  const phase = 3;
  const [name, id] = levelsInfo[phase].filter(
    levels => levels.name[byName ? 0 : 1] === level
  )[0].name;
  return byName ? id : name;
};

export const getDivision = (level, division) => {
  const byName = typeof level === "string";
  const phase = 3;
  const [name, id] = levelsInfo[phase]
    .filter(levels => levels.name[byName ? 0 : 1] === level)[0]
    .divisions.filter(d => d[byName ? 0 : 1] === division)[0];
  return byName ? id : name;
};

export const createSlug = (
  title,
  level,
  division,
  type,
  unite,
  postType,
  replacement = "-"
) => {
  const getUrlFriendlyStr = str => {
    return str.trim().replace(/\s/g, replacement);
  };
  if (type === "مستوى") {
    return `/${getUrlFriendlyStr(title)}`;
  }
  if (type === "وحدة") {
    return `/${getUrlFriendlyStr(title)}/${getUrlFriendlyStr(
      division
    )}-${getUrlFriendlyStr(level)}`;
  }
  if (type === "post") {
    return `/${getUrlFriendlyStr(title)}/${getUrlFriendlyStr(postType)}`;
  }

  return `/${getUrlFriendlyStr(title)}/${getUrlFriendlyStr(
    unite
  )}/${getUrlFriendlyStr(division)}-${getUrlFriendlyStr(level)}`;
};

export const daysToMilliSecond = (days = 0) => {
  return days * 24 * 3600 * 1000;
};

const localSt =
  typeof window !== "undefined"
    ? window.localStorage
    : {
        getItem: () => "{}",
        setItem: () => "",
        removeItem: () => "",
        clear: () => "",
      };

const get = key => JSON.parse(localSt.getItem(key));
const set = (key, value) => localSt.setItem(key, JSON.stringify(value));
const remove = key => localSt.removeItem(key);
const clear = () => localSt.clear();
const has = key => {
  const value = get(key);
  return !!value;
};
/**
 * local storage api
 * @param {string} key
 * @param {object} value
 * @return {object}
 */
export const ls = (key, value = null) => {
  if (value) {
    return set(key, value);
  } else {
    return get(key);
  }
};
ls.get = get;
ls.set = set;
ls.remove = remove;
ls.clear = clear;
ls.has = has;

export const isSSR = typeof window !== "undefined";

export const isUserUsingMobile = () => {
  let hasTouchScreen = false;
  if (isSSR) {
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
  }
  console.log("in is mobile", navigator.userAgent);

  return hasTouchScreen;
};
